const LINKS = [
  // {
  //   title: "Dashboard",
  //   icon: "calendar",
  //   pages:
  //       [
  //     {
  //       title: "Dashboard",
  //       path: "/dashboard", // Placeholder link
  //     },
  //     {
  //       title: "Blankett",
  //       path: "/match-summary", // Placeholder link
  //     },
  //   ],
  // },
  {
    title: "Mannschaft",
    icon: "users",
    pages: [
      {
        title: "Kader",
        path: "/kader", // Placeholder link
      },
      {
        title: "Spieler Anmelden",
        path: "/kader/spieler-anmelden", // Placeholder link
      },
      {
        title: "Trainer",
        path: "/kader/trainer-anmelden",
      },
      // {
      //   title: "Kapitan wechseln",
      //   path: "/blank", // Placeholder link
      // },
      // {
      //   title: "Rückennummern",
      //   path: "/blank", // Placeholder link
      // },
    ],
  },
  {
    title: "Transfers",
    icon: "users",
    pages: [
      {
        title: "Meine Transfers",
        path: "/transfer/index", // Placeholder link
      },
      {
        title: "Spieler",
        path: "/transfer/players", // Placeholder link
      }
      ],
  },
  {
    title: "Office Bereich",
    icon: "envelope",
    pages: [
      // {
      //   title: "Inbox",
      //   path: "/blank", // Placeholder link
      // },
      {
        title: "Finanzen",
        path: "/office/finanzen", // Placeholder link
      },
      {
        title: "Ligaordnung",
        path: "https://oekfb.eu/ligaordnung", // Placeholder link
      },
      {
        title: "Kontakt Daten",
        path: "/office/kontakt-daten", // Placeholder link
      },
      // {
      //   title: "Vertrag",
      //   path: "/office/vertrag", // Placeholder link
      // },
      // {
      //   title: "Tutorial",
      //   path: "/blank", // Placeholder link
      // },
    ],
  }
];

export default LINKS;
