import React, { useEffect, useState } from 'react';
import AuthService from '../../network/AuthService';
import ApiService from '../../network/ApiService';
import styles from './TransferList.module.css';

const TransferList = () => {
    const [transfers, setTransfers] = useState([]); // Initialize with an empty array
    const [team, setTeam] = useState(null);
    const authService = new AuthService();
    const apiService = new ApiService();

    useEffect(() => {
        fetchTransfers();
    }, []);

    const fetchTransfers = async () => {
        try {
            const teamID = authService.getTeamID();
            const authToken = authService.getAuthToken();

            if (teamID && authToken) {
                const response = await apiService.get(`transfers/team/${teamID}`);

                // Directly set the response if it is an array
                if (Array.isArray(response)) {
                    setTransfers(response); // Use response directly as it is already an array
                } else {
                    console.error('Transfers data is not available or is not an array.');
                    setTransfers([]); // Reset to empty array if response is not as expected
                }

                setTeam(response);
            } else {
                console.error('TeamID or AuthToken is missing.');
            }
        } catch (error) {
            console.error('Error fetching transfers:', error);
        }
    };

    const handleRejectTransfer = async (matchID) => {
        try {
            await apiService.get(`transfers/reject/${matchID}`);
            fetchTransfers(); // Refresh the data after rejecting the transfer
        } catch (error) {
            console.error(`Error rejecting transfer for player ${matchID}:`, error);
        }
    };

    return (
        <div className={styles.transfersList}>
            <table className={styles.table}>
                <thead>
                <tr>
                    <th className={styles.header}>Foto</th>
                    <th className={styles.header}>Spieler</th>
                    <th className={styles.header}>Antragsstatus</th>
                    <th className={styles.header}>Antragsdatum</th>
                    <th className={styles.header}>Aktion</th>
                    {/* New column for the action button */}
                </tr>
                </thead>
                <tbody>
                {transfers.map((transfer) => (
                    <tr key={transfer.id} className={styles.row}>
                        <td className={styles.cell}>
                            <img src={transfer.player_image} alt={transfer.player_name} className={styles.image} />
                        </td>
                        <td className={styles.cell}>{transfer.player_name}</td>
                        <td className={styles.cell}>{transfer.status.toUpperCase()}</td>
                        <td className={styles.cell}>
                            {new Date(transfer.created).toLocaleDateString('en-GB')}
                        </td>
                        <td className={styles.cell}>
                            {transfer.status.toUpperCase() !== "ABGELENT" && transfer.status !== "ANGENOMMEN" && (
                                <button
                                    className={styles.rejectButton}
                                    onClick={() => handleRejectTransfer(transfer.id)}
                                >
                                    Transfer Stornieren
                                </button>
                            )}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default TransferList;
