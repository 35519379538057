// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__J9Ba9 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
}
.styles_container__J9Ba9.styles_light__CpBdq {
  background: var(--widget);
  box-shadow: 0 1px 8px rgba(110, 110, 110, 0.1);
}
.styles_container__J9Ba9.styles_dark__uZB3J {
  background: var(--border);
}

.styles_field__bGqR0 {
  max-width: 220px !important;
  height: 264px !important;
  margin-top: 30px !important;
}
@media screen and (min-width: 768px) {
  .styles_field__bGqR0 {
    margin-top: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MatchMonthCard/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,kBAAA;AACJ;AACI;EACI,yBAAA;EACA,8CAAA;AACR;AAEI;EACI,yBAAA;AAAR;;AAIA;EACI,2BAAA;EACA,wBAAA;EACA,2BAAA;AADJ;AAII;EANJ;IAOQ,gBAAA;EADN;AACF","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    border-radius: 4px;\n\n    &.light {\n        background: var(--widget);\n        box-shadow: 0 1px 8px rgba(110, 110, 110, .1);\n    }\n\n    &.dark {\n        background: var(--border);\n    }\n}\n\n.field {\n    max-width: 220px !important;\n    height: 264px !important;\n    margin-top: 30px !important;\n\n    // tablet portrait\n    @media screen and (min-width: 768px) {\n        margin-top: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__J9Ba9`,
	"light": `styles_light__CpBdq`,
	"dark": `styles_dark__uZB3J`,
	"field": `styles_field__bGqR0`
};
export default ___CSS_LOADER_EXPORT___;
