import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TransferCell.module.scss';
import Spring from '@components/Spring';
import { useThemeProvider } from '@contexts/themeContext';
import { LazyLoadImage } from "react-lazy-load-image-component";
import ApiService from "../../network/ApiService";
import { formatISO } from 'date-fns'; // Import date-fns for ISO formatting

// Function to format the nationality string for German characters
const formatNationality = (nationality) => {
    return nationality
        .toLowerCase()
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ß/g, 'ss')
        .replace(/ /g, '')
        .replace(/[^a-z]/g, ''); // Remove any non-alphabetical characters
};

const getEligibilityClass = (eligibility) => {
    switch (eligibility) {
        case 'Spielberechtigt':
            return styles.eligible;
        case 'Gesperrt':
            return styles.suspended;
        case 'Warten':
            return styles.waiting;
        default:
            return '';
    }
};

const TransferCell = ({ player, team }) => {
    const { direction } = useThemeProvider();
    const [requestSent, setRequestSent] = useState(false); // State to track if request is sent
    const formattedNationality = formatNationality(player.nationality);
    const apiService = new ApiService();

    const getImageSrc = () => {
        if (player.eligibility === 'Warten') {
            return 'https://firebasestorage.googleapis.com/v0/b/oekfbbucket.appspot.com/o/adminfiles%2FpendingPlayer.png?alt=media&token=b504ecd3-3c5d-4f88-b629-e901c51f5bb4';
        }
        return player.image;
    };

    const handleTransferRequest = async () => {
        if (team && player) {
            console.log("Passed initial checks");

            // Ensure the date format is exactly what is expected by the backend
            const requestData = {
                player: player.id,
                team: team.id,
                origin: null,
                team_name: team.team_name || "NO NAME",
                player_name: player.name,
                created: formatISO(new Date()), // Use date-fns to ensure correct ISO format
                team_image: team.logo || "",
                player_image: player.image || "",
                status: "warten"
            };

            console.log("Request data:", requestData);

            try {
                const response = await apiService.post(`transfers/create`, requestData);

                if (response) { // Correct check for success
                    setRequestSent(true); // Update state to indicate the request has been sent
                } else {
                    console.error('Failed to send transfer request', response);
                }
            } catch (error) {
                console.error('Error sending transfer request:', error);
            }
        } else {
            console.error("Team or player data is missing");
        }
    };

    return (
        <Spring className={`card ${styles.card} ${styles[direction]}`}>
            <div className={styles.wrapper}>
                <div className={styles.textContent}>
                    <div className={styles.numberBox}>
                        <span className={styles.playerNumber}>{player.number ? player.number : '?'}</span>
                    </div>
                    <div className={styles.playerInfo}>
                        <h2 className={styles.playerName}>{player.name}</h2>
                        <p className={styles.playerSID}>SID: {player.sid}</p>
                        <div className={styles.nationalityRow}>
                            <img
                                src={`https://www.zeitzonen.de/templates/2014/dist/images/flags/${formattedNationality}.svg`}
                                alt={player.nationality}
                                className={styles.flagImage}
                            />
                            <span>{player.nationality}</span>
                        </div>
                        <p className={`${styles.playerPosition} ${getEligibilityClass(player.eligibility)}`}>
                            {player.eligibility}
                        </p>
                        <p className={styles.playerPosition}>{player.position}</p>

                        <button
                            className={styles.transferButton}
                            onClick={handleTransferRequest}
                            disabled={requestSent} // Disable button after request is sent
                            style={requestSent ? {opacity: 0.4} : {}}
                        >
                            {requestSent ? "Transfer Anfrage Gesendet" : "Transfer Anfrage senden"}
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles.imageWrapper}>
                <LazyLoadImage
                    className={styles.playerImage}
                    src={getImageSrc()}
                    alt={player.name}
                />
            </div>
        </Spring>
    );
};

TransferCell.propTypes = {
    player: PropTypes.shape({
        number: PropTypes.string,
        name: PropTypes.string.isRequired,
        nationality: PropTypes.string.isRequired,
        eligibility: PropTypes.string.isRequired,
        position: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
    }).isRequired,
    team: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        image: PropTypes.string,
    }), // Added team prop type for transfer request
};

export default TransferCell;
