// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_formContainer__\\+fGSd {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the left */
  gap: 20px;
  padding: 20px; /* Add some padding for better appearance */
}

.styles_imagePreviewContainer__onR14 {
  display: flex;
  justify-content: flex-start; /* Align image to the left */
  margin-bottom: 20px;
}

.styles_imagePreview__J0wkB {
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.styles_row__bzvI4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Reduce gap for more compact appearance */
}

.styles_footer__uUZ-t {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align buttons to the left */
  gap: 10px;
  margin-top: 20px;
}

@media screen and (min-width: 414px) {
  .styles_footer__uUZ-t {
    flex-direction: row;
    justify-content: flex-start; /* Align buttons to the left for mobile */
  }
}
@media screen and (min-width: 768px) {
  .styles_row__bzvI4 {
    display: flex;
    flex-direction: column; /* Keep inputs in a column layout */
  }
  .styles_footer__uUZ-t {
    flex-direction: row;
    justify-content: flex-start; /* Align buttons to the left for larger screens */
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/SetTrainer/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA,EAAA,8BAAA;EACA,SAAA;EACA,aAAA,EAAA,2CAAA;AACJ;;AAEA;EACI,aAAA;EACA,2BAAA,EAAA,4BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA,EAAA,2CAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA,EAAA,8BAAA;EACA,SAAA;EACA,gBAAA;AACJ;;AAEA;EACI;IACI,mBAAA;IACA,2BAAA,EAAA,yCAAA;EACN;AACF;AAEA;EACI;IACI,aAAA;IACA,sBAAA,EAAA,mCAAA;EAAN;EAGE;IACI,mBAAA;IACA,2BAAA,EAAA,iDAAA;EADN;AACF","sourcesContent":[".formContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start; /* Align content to the left */\n    gap: 20px;\n    padding: 20px; /* Add some padding for better appearance */\n}\n\n.imagePreviewContainer {\n    display: flex;\n    justify-content: flex-start; /* Align image to the left */\n    margin-bottom: 20px;\n}\n\n.imagePreview {\n    max-width: 150px;\n    max-height: 150px;\n    border-radius: 50%;\n    object-fit: cover;\n}\n\n.row {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 10px; /* Reduce gap for more compact appearance */\n}\n\n.footer {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start; /* Align buttons to the left */\n    gap: 10px;\n    margin-top: 20px;\n}\n\n@media screen and (min-width: 414px) {\n    .footer {\n        flex-direction: row;\n        justify-content: flex-start; /* Align buttons to the left for mobile */\n    }\n}\n\n@media screen and (min-width: 768px) {\n    .row {\n        display: flex;\n        flex-direction: column; /* Keep inputs in a column layout */\n    }\n\n    .footer {\n        flex-direction: row;\n        justify-content: flex-start; /* Align buttons to the left for larger screens */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `styles_formContainer__+fGSd`,
	"imagePreviewContainer": `styles_imagePreviewContainer__onR14`,
	"imagePreview": `styles_imagePreview__J0wkB`,
	"row": `styles_row__bzvI4`,
	"footer": `styles_footer__uUZ-t`
};
export default ___CSS_LOADER_EXPORT___;
