import React, { useEffect, useState } from 'react';
import ApiService from './../network/ApiService';
import PageHeader from '@layout/PageHeader';
import styles from '@widgets/PlayerKaderInfo/Kader.module.scss';
import AuthService from "../network/AuthService";
import styles2 from './TransferPlayers.module.css'; // Corrected path
import TransferCell from "@widgets/TransferCell/TransferCell";

const TransferPlayers = () => {
    const [players, setPlayers] = useState([]);
    const [team, setTeam] = useState(null); // State for team information
    const [loading, setLoading] = useState(true); // State for loading
    const [searchTerm, setSearchTerm] = useState(''); // State for search input
    const apiService = new ApiService();
    const authService = new AuthService();

    const teamID = authService.getTeamID();

    useEffect(() => {
        fetchTeamData();
        fetchPlayersData();
    }, []);

    // Function to fetch all players data
    const fetchPlayersData = async () => {
        setLoading(true);
        try {
            const data = await apiService.get(`transfers/options/${teamID}`);
            const filteredPlayers = data.filter(player => player.email); // Filter players with non-empty email
            setPlayers(filteredPlayers);
        } catch (error) {
            console.error("Failed fetching players:", error);
        } finally {
            setLoading(false);
        }
    };

    // Function to fetch team data
    const fetchTeamData = async () => {
        if (teamID) {
            try {
                const teamData = await apiService.get(`teams/${teamID}`);
                setTeam(teamData);
            } catch (error) {
                console.error("Error fetching team data:", error);
            }
        } else {
            console.error("No team ID found in cookies.");
        }
    };

    // Function to fetch players by team name
    const fetchPlayersByName = async (searchValue) => {
        setLoading(true); // Set loading state when fetching data
        try {
            const searchData = await apiService.get(`players/transfer/name/${searchValue}`);
            setPlayers(searchData); // Update players based on search results
        } catch (error) {
            console.error("Error fetching players by name:", error);
        } finally {
            setLoading(false); // Stop loading after search
        }
    };

    // Handle search input submission
    const handleSearchSubmit = (event) => {
        event.preventDefault();
        if (searchTerm.trim()) {
            fetchPlayersByName(searchTerm);
        } else {
            fetchPlayersData(); // Fetch all players if the search term is empty
        }
    };

    return (
        <>
            <PageHeader title="Transfer Möglichkeiten" />
            {loading ? (
                <div className="spinner" />
            ) : (
                <>
                    <div style={{ backgroundColor: "#24292B", padding: "25px", marginBottom: "10px" }}>
                        <div className={styles2.lightH}>
                            Nur Spieler mit einer aktualisierten Email Adresse sind für Transfers berechtigt:
                        </div>
                        <div className={styles2.infoText}>
                            Bitte die Email Adressen der Spieler ihrer Mannschaft Aktualisieren
                        </div>
                    </div>
                    {/* Search Bar */}
                    <form style={{ padding: "10px 25px" }} onSubmit={handleSearchSubmit}>
                        <input
                            className="field"
                            type="search"
                            placeholder="Suchen sie nach Spielern bei Namen..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <button className="btn" type="submit">
                            Suchen
                        </button>
                    </form>
                    <div className={styles.kaderGrid}>
                        {/* Render TransferCell only if team data is available */}
                        {team && players.map((player) => (
                            <TransferCell key={player.id} player={player} team={team} />
                        ))}
                        {/* Add a message if no players match the search criteria */}
                        {team && players.length === 0 && (
                            <div style={{ color: "white", textAlign: "center" }}>
                                Keine Spieler gefunden.
                            </div>
                        )}
                        {/* Add a message if team data is not yet loaded */}
                        {!team && <div>Loading team data...</div>}
                    </div>
                </>
            )}
        </>
    );
};

export default TransferPlayers;
