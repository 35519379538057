// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_tabs_list__yHduT {
  margin: 16px 0 24px;
}

.styles_row__YVO9e {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.styles_social_item__RMczV {
  display: flex;
  align-items: center;
  gap: 10px;
}
.styles_social_item__RMczV .styles_icon__33Drc {
  font-size: 20px;
  color: var(--highlight);
}

.styles_social_connect__coZw7 {
  font-size: 0.75rem;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 28px;
  transition: color var(--transition);
}
.styles_social_connect__coZw7:hover, .styles_social_connect__coZw7:focus {
  color: var(--highlight);
}

.styles_footer__8yPTN {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

@media screen and (min-width: 414px) {
  .styles_footer__8yPTN {
    flex-direction: row;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 768px) {
  .styles_row__YVO9e {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .styles_footer__8yPTN {
    flex-direction: row;
    justify-content: flex-end;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/AddPlayer/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACI;EACI,eAAA;EACA,uBAAA;AACR;;AAGA;EACI,kBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,iBAAA;EACA,mCAAA;AAAJ;AAEI;EACI,uBAAA;AAAR;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AADJ;;AAKA;EACI;IACI,mBAAA;IACA,2BAAA;EAFN;AACF;AAMA;EACI;IACI,aAAA;IACA,qCAAA;EAJN;EAOE;IACI,mBAAA;IACA,yBAAA;EALN;AACF","sourcesContent":[".tabs_list {\n    margin: 16px 0 24px;\n}\n\n.row {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.social_item {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n\n    .icon {\n        font-size: 20px;\n        color: var(--highlight);\n    }\n}\n\n.social_connect {\n    font-size: 0.75rem;\n    width: fit-content;\n    margin-left: 28px;\n    transition: color var(--transition);\n\n    &:hover, &:focus {\n        color: var(--highlight);\n    }\n}\n\n.footer {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    margin-top: 20px;\n}\n\n// iPhone XR\n@media screen and (min-width: 414px) {\n    .footer {\n        flex-direction: row;\n        justify-content: flex-start;\n    }\n}\n\n// tablet portrait\n@media screen and (min-width: 768px) {\n    .row {\n        display: grid;\n        grid-template-columns: repeat(2, 1fr);\n    }\n\n    .footer {\n        flex-direction: row;\n        justify-content: flex-end;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs_list": `styles_tabs_list__yHduT`,
	"row": `styles_row__YVO9e`,
	"social_item": `styles_social_item__RMczV`,
	"icon": `styles_icon__33Drc`,
	"social_connect": `styles_social_connect__coZw7`,
	"footer": `styles_footer__8yPTN`
};
export default ___CSS_LOADER_EXPORT___;
